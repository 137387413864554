<template>
  <div class="page flex-col" v-wechat-title="this.title">

    <div class="group_1 flex-col">
      <div class="group_20 flex-col">
        <div class="group_21 flex-row justify-between">
          <a href="/#/softwareList"><span class="text_54">软件商城</span></a>
          <img
              class="thumbnail_4"
              referrerpolicy="no-referrer"
              src="../../img/product/productDescription/SketchPnge9688f4305eea94c326977c63c82a87a21443ce8b596db586a297358f819111b.png"
          />
          <span class="text_55">{{productParams[0].softwareGoodsName}}</span>
        </div>
      </div>
      <div class="group_22 flex-col">
        <div class="box_1 flex-col"></div>
        <div class="box_3 flex-row justify-between">
          <div class="box-flex">
            <div class="box_4 flex-col">
              <img
                  class="image_2"
                  referrerpolicy="no-referrer"
                  :src="goodImg"
              />
            </div>
            <div class="box_7 flex-row">
              <div class="box_7-left-icon flex-col">
                <!--            <img class="" src="@/img/product/productDescription/left_slices.png" @click="box_7_left(productImgList,5)" />-->
              </div>

              <div class="box_7-left" id="box_7_left_parent">
                <!--            <div class="group_9 flex-col">
                              <div class="section_2 flex-col">
                                <img src="@/img/product/productDescription/SketchPngc876d09e3bbcf4fd028a9864a59d96d65f2caaef11d5da4ff73c33544e614aed.png"/>
                              </div>
                            </div>-->
                <div class="box_7_left_swiper_box swiper-box flex-row" :style="{transform:'translateX('+this.productImgCurrentOffset+'px)'}" id="box_7_left_swiper_box">
                  <div class="group_7 flex-col" v-for="(item, index) in productImgList" :key="index">
                    <div class="block_1 flex-col" @click="changeImg(item)" style="cursor: pointer">
                      <img :src="item.goodsImageUrl"/>
                    </div>
                  </div>
                </div>
              </div>

              <div class="box_7-left-icon flex-col">
                <!--            <img class="" src="@/img/product/productDescription/right_slices.png" @click="box_7_right(productImgList,5)"/>-->
              </div>
          </div>
          </div>

          <div class="box_5 flex-col">
            <div class="text-wrapper_3">
<!--              <span class="text_9">Surface&nbsp;Go</span>-->
              <!-- 商品名称 -->
                <span class="text_10">{{productParams[0].softwareGoodsName}}</span>
              <!--  关注 -->
                <a href="javascript:;" class="text_10_follow" :class="{text_10_followed: followFlag}" @click="follow">{{followText}}</a>
            </div>
<!--            <span class="text_11" v-html="productParams[0].goodsDescription"></span>-->
        <!--    限时优惠活动信息          -->
            <div class="software-body-activity flex-row" v-if="ecGoodsActivity != null">
              <div class="activity-title">
                      限时优惠
              </div>
              <div class="activity-content">
                  {{ecGoodsActivity.activityContent}}
              </div>
            </div>
        <!--     领取优惠券         -->
            <div class="software-body-coupon" v-if="couponList.length > 0">
              <div class="software-coupon">
                     优惠券：
              </div>
              <div class="coupon-list-window" id="coupon-list-window">
                <ul>
                  <li class="coupon-li" v-for="(item,index) in couponList" :key="index">
                    <div class="coupon-div">
                      <div class="coupon-bg">
                        <div class="coupon-price">
                           限定
                        </div>
                        <div class="coupon-title">
                          优惠券
                        </div>
                      </div>
                      <div class="coupon-description">
                          {{item.couponPrice}}元优惠券，满{{item.couponCondition}}元可用。
                      </div>
                      <div class="coupon-btn">
                        <button @click="receiveCoupon(item)"
                                class="receive-coupon-btn"
                                :disabled="item.couponDisabled"
                                :class='{"none-stock":item.couponDisabled}'>
                            领取
                        </button>
                      </div>
                    </div>
                  </li>
                </ul>
                <div class="coupon-more" @click="autoHeight" big_height="1" id="coupon-more">
                  <span>更多</span>
                    <i class="nav-item-icon"></i>
                </div>
              </div>
            </div>
            <!-- 选择版本 -->
            <div class="box_66">


            <span class="text_12"><span>选择版本：</span></span>

            <div class="product_scroll" :style="{'height': productSpe.length > 2 ? '17vw' : '8vw'}">

<!--              <div class="box_6 flex-row justify-between cursor-item" v-for="(item, index) in productSpe" :key="index">-->
<!--                <span class="text_12"><span>选择版本：</span></span>-->
                <div class="flex-col text-wrapper_4 cursor-item" v-for="(item, index) in productSpe" :key="index" :class="{'select_color':item.isSelect,'no_select_color':!item.isSelect}" @click="selectProduct(item,index)">
                  <span class="text_13" :class="{'select_font_color':item.isSelect,'no_select_font_color':!item.isSelect}" >
                  {{productSpe[0].name}}
                    <!-- Srfc&nbsp;Go2&nbsp;P/4/64COMM&nbsp;afo&nbsp;SC&nbsp;ChnSimp&nbsp;China&nbsp;Hdwr&nbsp;Commercial&nbsp;Platinum -->
                  </span>
                </div>
<!--              </div>-->
            </div>
            </div>
            <!-- 单价 -->
            <div style="margin-top: 12px;margin-left: 6px;" >
              <span class="text_17">单&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;价：</span>
              <span class="text_18">¥{{price}}</span>
            </div>
            <div style="margin-top: 12px;margin-left: 6px;">
            <template v-if="productParams[0].softwareGoodsType == 1">
              <span class="text_17">选择时长：</span>
              <el-input-number size="small" class="time_input" :min="1" :max="24" v-model="time"></el-input-number>
              <span class="text_21">（年）</span>
            </template>
            <template v-else>
              <span class="text_17">选择数量：</span>
              <el-input-number size="small" class="time_input"  :min="1" :max="24" v-model="amount"></el-input-number>
              <span class="text_21">（台）</span>
            </template>
            </div>
            <div  v-if="productParams[0].softwareGoodsType == 1" style="margin-top: 12px;margin-left: 6px;">
              <span class="text_17" >选择数量：</span>
              <el-input-number size="small" class="time_input" :min="1" :max="24"  v-model="amount"></el-input-number>
              <span class="text_21">（件）</span>
            </div>
            <div  style="margin-top: 12px;margin-left: 6px;">
              <span class="text_17">合&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;计：</span>
              <span class="text_18">¥{{totalPrice}}</span>
            </div>
            <!-- 按钮 -->
            <div class="box_10 "  style="margin-top: 18px;margin-left: 6px;">
              <button class="text-wrapper_10 flex-col cursor-item" @click="buyGood"><span class="text_24">立即购买</span></button>
              <!--          <button class="text-wrapper_11 flex-col cursor-item" @click="addCar"><span class="text_25">加入购物车</span></button>-->
              <div class="text-wrapper_12 flex-col cursor-item"><span class="text_26">咨询客服</span></div>
            </div>
          </div>
        </div>

<!--          <div class="box_22 flex-col">  <img class="" src="@/img/product/productDescription/right_slices.png" @click="right"></img> </div>-->
        <!--    softwareGoodsType:1 => 该商品为软件      -->
<!--          <template v-if="productParams[0].softwareGoodsType == 1">-->
<!--          <span class="text_19">选择时长：</span>-->
<!--            <el-input-number size="small" class="time_input"   v-model="time"></el-input-number>-->
<!--          <span class="text_21">（年）</span>-->
<!--          </template>-->
<!--          <template v-else>-->
<!--              <span class="text_19">选择数量：</span>-->
<!--              <el-input-number size="small" class="time_input"   v-model="amount"></el-input-number>-->
<!--              <span class="text_21">（台）</span>-->
<!--          </template>-->
        <div class="group_25 flex-col">
          <div class="text-wrapper_26 flex-row cursor-item"><span class="text_56" >相关推荐</span></div>

          <div class="group_26 flex-row"><div class="block_12 flex-col"></div></div>

          <div class="group_27 flex-row">
            <!--          <div class="box_21 flex-col cursor-item"> <img class="" src="@/img/product/productDescription/left_slices.png" @click="left(loopData0,4)"></img> </div>-->

            <div id="father" class="grid-content bg-purple">

              <div class="list_1 flex-row swiper-box" id="swiper-box" :style="{transform:'translateX('+currentOffset+'px)'}">

                <div class="list-items_1 flex-col cursor-item" v-for="(item, index) in loopData0" :key="index" @click="openProductDetail(item.softwareGoodsId)">
                  <!--              <img class="slices-img" :style="{minWidth:width}" src="https://shadow.elemecdn.com/app/element/hamburger.9cf7b091-55e9-11e9-a976-7f4d0b07eef6.png" />-->
                  <div class="section_7 flex-col" :style="{minWidth:width}">
                    <!--                <img-->
                    <!--                    v-if="item.slot1 === 1"-->
                    <!--                    class="image_17"-->
                    <!--                    referrerpolicy="no-referrer"-->
                    <!--                    :src="item.specialSlot1.lanhuimage0"-->

                    <!--                />-->
                    <img
                        class="image_18"
                        referrerpolicy="no-referrer"
                        :src="item.imgUrl"

                    />
                    <span class="text_57" v-html="item.softwareGoodsName"></span>
                    <span class="text_58">订阅版、乐享售后服务</span>
                    <span class="text_59">￥{{item.goodsPrice}}</span>
                  </div>

                </div>

              </div>

            </div>
            <!--          <div class="box_22 flex-col cursor-item">  <img class="" src="@/img/product/productDescription/right_slices.png" @click="right(loopData0,4)"></img> </div>-->
          </div>

        </div>
        <!-- 底部商品介绍-->
        <div class="box_13 flex-col">
          <div class="box_16 flex-col">
            <!-- 底部标签 -->
            <div class="text-wrapper_20 flex-row justify-between">
              <!-- <span class="text_27">商品介绍<div class="group_11 flex-col"></div></span>-->
              <span class="text_27 cursor-item" :class="{'introduce_select':introduceSelect[0].isSelect,'introduce_no_select':!introduceSelect[0].isSelect}" @click="introduceSelectMethod(introduceSelect[0])">{{introduceSelect[0].content}}<div class="group_11 flex-col" v-if="introduceSelect[0].isSelect"></div></span>
              <span class="text_28 cursor-item" :class="{'introduce_select':introduceSelect[1].isSelect,'introduce_no_select':!introduceSelect[1].isSelect}" @click="introduceSelectMethod(introduceSelect[1])">{{introduceSelect[1].content}}<div class="group_11 flex-col" v-if="introduceSelect[1].isSelect"></div></span>
              <span class="text_29 cursor-item" :class="{'introduce_select':introduceSelect[2].isSelect,'introduce_no_select':!introduceSelect[2].isSelect}" @click="introduceSelectMethod(introduceSelect[2])">{{introduceSelect[2].content}}<div class="group_11 flex-col" v-if="introduceSelect[2].isSelect"></div></span>
              <span class="text_30 cursor-item" :class="{'introduce_select':introduceSelect[3].isSelect,'introduce_no_select':!introduceSelect[3].isSelect}" @click="introduceSelectMethod(introduceSelect[3])">{{introduceSelect[3].content}}<div class="group_11 flex-col" v-if="introduceSelect[3].isSelect"></div></span>
            </div>
            <!-- 商品介绍内容 -->
            <template v-if="introduceSelect[0].isSelect">
              <p v-if="productParams[0].goodsDescription == null || productParams[0].goodsDescription == ''" class="text_31">该商品暂无介绍 </p>
              <p v-else-if="productParams[0].goodsDescription.indexOf('<p>') == -1" class="text_31">
                {{productParams[0].goodsDescription}}
              </p>
              <span v-else class="text_31_html" v-html="productParams[0].goodsDescription"></span>
            </template>
            <!-- 交易条款内容 -->
            <template v-if="introduceSelect[1].isSelect">
            <span class="text_31">
              <p style="font-size: 0.72vw;font-weight: bold;color: #EC7816;">*
                                              客户在接受商品订购与送货的同时，有义务遵守以下交易条款。您在伊登云商城下订单之前或接受伊登云商城送货之前，请您仔细阅读以下条款：</p>
              <p style="margin: 37px 0 25px 0">* 订购的商品价格以您下订单时软件商城网上价格为准。</p>
              <p style="margin-bottom: 25px">*
                                              请清楚准确地填写您的真实姓名、送货地址及联系方式。因如下情况造成订单延迟或无法配送等，软件商城将不承担责任：</p>
              <p style="margin-bottom: 25px;margin-left: 12px;"> A. 客户提供错误信息和不详细的地址;</p>
              <p style="margin-bottom: 25px;margin-left: 12px;"> B.
                                              货物送达无人签收，由此造成的重复配送所产生的费用及相关的后果;</p>
              <p style="margin-bottom: 25px;margin-left: 12px;"> C.
                                              不可抗力，例如：自然灾害、交通戒严、突发战争等。</p>
              <p style="margin-bottom: 25px">*
                                              安全性：无论您是电话订购商品或是网络订购商品，我们会保证交易信息的安全，并由软件商城授权的员工处理您的订单。</p>
              <p style="margin-bottom: 25px">*
                                              隐私权：软件商城尊重您的隐私权，在任何情况下，我们都不会将您的个人和订单信息出售或泄露给任何第三方(国家司法机关调取除外)。
                                              我们从网站上或电话中得到的所有客户信息仅用来处理您的相关订单。</p>
              <p style="margin-bottom: 25px">*
                                              免责：如因不可抗力或其它软件商城无法控制的原因使软件商城销售系统崩溃或无法正常使用导致网上交易无法完成或丢失有关的信息、
                                              记录等，软件商城会尽可能合理地协助处理善后事宜，并努力使客户免受经济损失。</p>
              <p style="margin-bottom: 25px">*
                                              客户监督：软件商城希望通过不懈努力，为客户提供最佳服务，软件商城在给客户提供服务的全过程中接受客户的监督。</p>
              <p style="margin-bottom: 25px">*
                                              争议处理：如果客户与软件商城之间发生任何争议，可依据当时双方所认定的协议及相关法律进行解决。</p>
            </span>
            </template>
            <!-- 售后保障内容 -->
            <template v-if="introduceSelect[2].isSelect">
              <span class="text_31">
                <p>客户购买本网站商品后，若所购买商品在使用过程中出现问题，可以联系本网站在线售后客服获取解决方案。本网站售后客服将依据深圳市伊登软件有限公司制订的本服务规则提供售后服务：</p>
                <p style="margin: 37px 0 25px"> 1.由于音像制品和计算机软件类商品的特殊性，且有《中华人民共和国消费者权益保护法》规定：在线下载或者消费者拆封的音像制品、计算机软件等数字化商品，不享受7日无理由退货。</p>
                <p style="margin-bottom: 25px">
                    客户在收到本网站提供的激活码后不得以其他任何理由要求退换货。若客户收到激活码（含序列号、注册码等其他形式的认证信息）并成功激活软件的，将不再享有退换货权利，本网站亦不提供针对此情形下的退换货服务。</p>
                <p style="margin-bottom: 25px">
                    2.客户在使用软件过程中遇到其他问题的，可以在工作日9:00至18:00联系本网站售后客服寻求协助。
                </p>
                <p>
                    3.本《售后服务规则》最终解释权归本网站所有。
                </p>
              </span>
            </template>
            <!-- 授权说明内容 -->
            <template v-if="introduceSelect[3].isSelect">
              <span class="text_31"><p>伊登云商城所有商品都有厂商授权证书。</p></span>
            </template>
          </div>
        </div>
      </div>

      </div>
      <!-- 头部面包屑 -->

      <!-- 相关推荐 -->

      <el-dialog title="确认订单信息" :visible.sync="dialogFormVisible" v-if="showOrder">
        <el-form :model="orderInfo" :rules="orderRules">
          <el-form-item label="用户类型">
            <el-radio-group v-model="orderInfo.userType">
              <el-radio :label="0">企业</el-radio>
              <el-radio :label="1">个人</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="联系人" prop="name">
            <el-input v-model="orderInfo.name" autocomplete="off" style="width: 300px;" ></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="phone">
            <el-input v-model="orderInfo.phone" autocomplete="off" style="width: 300px;"></el-input>
          </el-form-item>
          <el-form-item v-if="orderInfo.userType == 0" label="企业名称">
            <el-input v-model="orderInfo.companyName" autocomplete="off" style="width: 300px;"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer">
          <el-button @click="dialogFormVisible = false" class="clear-button">取 消</el-button>
          <el-button type="primary" @click="sumbitOrderInfo" class="order-button">确 定</el-button>
        </div>
      </el-dialog>
    </div>

</template>

<script>
import productApi from "../../api/software/productApi";
import md5Js from "../../utils/md5";
import contractApi from "../../api/buyer/transaction/contractApi";

export default {
  name: "productDescription",
  data() {
    return {
      title: '',
      followText: "关注+",
      followFlag: true,
      ecGoodsActivity: {}, // 活动信息
      couponList: [], // 优惠券列表
      amount: 1, // 购买数量
      time: 1, // 购买时长
      totalPrice: 0,
      specsId: '',        // 规格id
      specsName: '',      // 规格名称
      specsTypeId: '',    // 规格类型id
      specsTypeName: '',  // 规格类型名称
      showOrder: true,
      dialogFormVisible: false, // 控制是否弹出dialog
      orderInfo: {
        userType: 0, // 0是企业，1是个人
        name: "",
        phone: "",
        companyName: "",
      },
      orderRules: {
        name: [
          { required: true, message: "请输入联系人名称", trigger: "blur" },
        ],
        phone: [
          { required: true, message: "请输入手机号码" },
          { type: "phone", message: "手机号码格式不正确", teigger: "blur" },
        ],
      },
      softwareGoodsId: "0d4bc967-4ab3-453a-af0f-6c10985131bd",
      isSelect: true,
      currentOffset: 0,
      width: "",
      fatherWith: 0,
      box: "", // dom
      price: 0,
      productImgCurrentOffset: 0,
      productImgWidth: "",
      productImgFatherWith: 0,
      productImgBox: "",
      productParams: [], // 商品详情
      productSpe: [], // 商品选择版本
      loopData0: [
      ],
      productImgList: [
      ],
      productSelect: [
        {
          isSelect: true,
        },
        {
          isSelect: false,
        },
        {
          isSelect: false,
        },
        {
          isSelect: false,
        },
        {
          isSelect: false,
        },
      ],
      introduceSelect: [
        {
          content: "商品介绍",
          isSelect: true,
        },
        {
          content: "交易条款",
          isSelect: false,
        },
        {
          content: "售后保障",
          isSelect: false,
        },
        {
          content: "授权说明",
          isSelect: false,
        },
      ],

      constants: {},
      goodImg: "",
    };
  },
  mounted() {
    this.$store.commit('setCheckIndex', 2);
    this.setCardWidth("#father", 4);
    this.box = document.querySelector("#swiper-box");
    this.productImgBox = document.querySelector("#box_7_left_swiper_box");
    // this.setCardWidthBox7("#box_7_left_parent", 5);
    window.addEventListener("resize", () => {
      this.currentOffset = 0;
      this.setCardWidth("#father", 4);
      this.productImgCurrentOffset = 0;
      // this.setCardWidthBox7("#box_7_left_parent", 5);
    });
    this.getSoftwareGoodsInfoById(); // 获取商品详情
    this.isFollow(); // 判断用户是否关注该商品
  },
  /*  destroyed() {
    this.websocketOnClose();
  },*/
  methods: {
    openProductDetail(productId){
      this.$router.push({path: `/ProductDescription`,query:{productId: productId}});
    },
      listRecommond(softwareGoodsId, goodsLabel) {
          productApi.recommondList({
              pageNo: 1,
              pageSize: 4,
              goodsLables: goodsLabel,
              softwareGoodsId: softwareGoodsId
          }).then(res => {
              let data = res.data;
              if(data) {
                  this.loopData0 = data.records;
              }
          })
      },
    // 点击关注事件
    follow() {
      console.log("follow");
      let obj = this;
      // 判断用户是否登录
      if (this.$store.state.userInfo.userInfo == "" || this.$store.state.userInfo.userInfo == undefined) {
        this.$router.push({ path: "/login" });
        return;
      }
      if (obj.followFlag) {
        // 已经关注,取消关注,调用取消关注
        obj.delFollow();
      } else {
        // 没有关注，新增关注
        obj.addFollow();
      }
    },
    // 取消关注
    delFollow() {
      let obj = this;
      productApi
        .delFollowByObjectId({ objectId: obj.softwareGoodsId })
        .then((result) => {
          if (result.code == 200) {
            obj.followText = "关注+";
            obj.followFlag = false;
            obj.$message.success("取消关注！");
          }
        });
    },
    // 新增关注
    addFollow() {
      let obj = this;
      productApi
        .addFollowByObjectId({
          objectId: obj.softwareGoodsId,
          type: 4,
          classificationId:
            obj.productParams[0].classifyBrandId == null
              ? obj.productParams[0].classifyFunctionId
              : obj.productParams[0].classifyFunctionId == null
              ? obj.productParams[0].classifyIndustryId == null
              : obj.productParams[0].classifyBrandId,
        })
        .then((res) => {
          if (res.code == 200) {
            obj.$message("已关注！");
            obj.followFlag = true;
            obj.followText = "已关注";
          } else {
            obj.$message.error(res.message);
          }
        });
    },
    // 判断用户是否已经关注
    isFollow() {
      let obj = this;
      // 判断用户是否登录
      if (this.$store.state.userInfo.userInfo == "" ||this.$store.state.userInfo.userInfo == undefined) {
        return;
      }
      productApi
        .isFollowByObjectId({ objectId: this.softwareGoodsId })
        .then((res) => {
          if (res.code == 200) {
            obj.followFlag = false;
            obj.followText = "已关注";
          } else {
            obj.followFlag = true;
            obj.followText = "关注+";
          }
        });
    },
    // 计数器加法
    addition() {
      this.amount++;
      this.countTotalPrice();
    },
    // 计数器减法
    subtraction() {
      var a = this.amount == 1;
      console.log("a", a);
      if (this.amount == 1) {
        return;
      }
      this.amount--;
      this.countTotalPrice();
    },
    // 购买时长减少
    timeCut() {
      if (this.time == 1) {
        return;
      }
      this.time--;
      this.countTotalPrice();
    },
    // 购买时长增加
    timePlus() {
      this.time++;
      this.countTotalPrice();
    },
    // 计算总价
    countTotalPrice() {
      console.log('------------------------')
      let obj = this;
      //合计
      obj.totalPrice = obj.price * obj.amount * obj.time;
      obj.totalPrice = obj.totalPrice.toFixed(2);
    },
    // 获取商品详情数据
    getSoftwareGoodsInfoById() {
      productApi
        .getSoftwareGoodsInfoById({ softwareGoodsId: this.softwareGoodsId })
        .then((res) => {
          var specsTypes = res.data.specsTypes;
          var softwareSpecs = res.data.softwareSpecs;
          let softwareInfo = res.data.softwareGoodsVO;
          this.productParams.push(softwareInfo); // 商品详情
          this.title = this.productParams[0].softwareGoodsName + '- 伊登SaaS商城'
            //相关推荐
            console.log('info', softwareInfo)
            this.listRecommond(softwareInfo.softwareGoodsId, softwareInfo.goodsLables);

          this.ecGoodsActivity = res.data.ecGoodsActivity; // 活动信息
          console.log(this.productParams);
          // 商品图片
          res.data.goodsImages.forEach((item) => {
            this.productImgList.push(item);
          });
          console.log(this.productImgList);
          this.goodImg = this.productImgList[0].goodsImageUrl; // 默认显示第一张图片
          // 选择版本
          specsTypes.forEach((specsType) => {
            softwareSpecs.forEach((soft) => {
              var item = {
                name:
                  specsType.specsTypeName +
                  "\xa0\xa0\xa0\xa0\xa0" +
                  soft.specsName,
                specsName: soft.specsName,
                specsTypeName: specsType.specsTypeName,
                isSelect: false,
                specsTypeId: specsType.specsTypeId,
                softwareGoodsId: specsType.softwareGoodsId,
                specsId: soft.specsId,
                goodsMarketPrice: soft.goodsMarketPrice,
                goodsPrice: soft.goodsPrice,
                paymentType: soft.paymentType,
              };

              this.productSpe.push(item);
            });
          });
          console.log(this.productSpe);
          this.productSpe[0].isSelect = true;
          this.specsId = this.productSpe[0].specsId
          this.specsName = this.productSpe[0].specsName
          this.specsTypeId = this.productSpe[0].specsTypeId
          this.specsTypeName = this.productSpe[0].specsTypeName
          this.price = this.productSpe[0].goodsPrice;
          this.totalPrice = this.price * this.amount;
        });
      this.getSoftwareCoupons(); // 查询优惠券列表
    },
    // 查询优惠券列表
    getSoftwareCoupons() {
      if (this.$store.state.userInfo.userInfo == "" || this.$store.state.userInfo.userInfo == undefined) {
        return;
      }
      let obj = this;
      productApi
        .getSoftwareCoupons({ softwareGoodsId: obj.softwareGoodsId })
        .then((result) => {
          if (result.code == 200) {
            obj.couponList = result.data;
            if (obj.couponList.length == 1) {
              //如果只有一条数据设定ul高度为51
              setTimeout(function () {
                // $(".coupon-list-window").children("ul").height(51);
                document.getElementById(
                  "coupon-list-window"
                ).children[0].style.height = "51px";
              }, 100);
            }
            obj.couponList.forEach((item) => {
              if (item.couponUsedNum == item.couponNum) {
                item.couponDisabled = true;
                return;
              }
            });
          }
        });
    },
    //领取优惠券
    receiveCoupon(item) {
      let obj = this;
      if (this.$store.state.userInfo.userInfo == "" || this.$store.state.userInfo.userInfo == undefined) {
        this.$router.push({ path: "/login" });
        return;
      }
      productApi
        .syncReceiveCoupon({ couponId: item.couponId })
        .then((result) => {
          if (result.code == 200) {
            obj.$message.success("成功领取优惠券！");
            obj.getSoftwareCoupons();
          }
          if (result.code == 209) {
            obj.$message.error("领取优惠券失败，请重试！");
          }
          if (result.code == 400) {
            obj.$message.error("此账户已领取过该优惠券，请勿重复领取");
          }
        });
    },
    // 控制优惠券样式
    autoHeight() {
      let dropDown = document.getElementById("coupon-more");
      let height = dropDown.getAttribute("big_height");
      if (height > 0) {
        dropDown.children[0].innerHTML = "收起";
        dropDown.setAttribute("big_height", "0");
        dropDown.children[1].style.transform = "rotateX(180deg)";
        document
          .getElementById("coupon-list-window")
          .children[0].classList.add("autoHeight");
      } else {
        dropDown.children[0].innerHTML = "更多";
        dropDown.setAttribute("big_height", "1");
        document
          .getElementById("coupon-list-window")
          .children[0].classList.remove("autoHeight");
        dropDown.children[1].style.transform = "rotateX(360deg)";
      }
    },
    setCardWidth(id, num) {
      const father = document.querySelector(id);
      console.log('father.clientWidth;',father)
      this.fatherWith = father.clientWidth;
      console.log("fatherWidth", this.fatherWith);
      this.width = this.fatherWith / num + "px";
    },
    setCardWidthBox7(id, num) {
      const father = document.querySelector(id);
      console.log("fatherWidth", this.productImgFatherWith);
      this.productImgFatherWith = father.clientWidth;
      console.log("fatherWidth", this.productImgFatherWith);
      this.width = this.productImgFatherWith / num + "px";
    },
    right(data, num) {
      console.log(this.currentOffset);
      console.log(this.loopData0.length);
      if (this.currentOffset <= (-this.fatherWith / num) * (data.length - num))
        return;
      this.currentOffset -= this.fatherWith / num;
    },
    left(data, num) {
      console.log(this.currentOffset);
      if (this.currentOffset >= -2) return;
      this.currentOffset += this.fatherWith / num;
    },
    box_7_right(data, num) {
      console.log(this.productImgCurrentOffset);

      console.log(this.productImgFatherWith);

      console.log((-this.productImgFatherWith / num) * (data.length - num));
      if (
        this.productImgCurrentOffset <=
        (-this.productImgFatherWith / num) * (data.length - num)
      )
        return;
      console.log(123);
      this.productImgCurrentOffset -= this.productImgFatherWith / num;
    },
    box_7_left(data, num) {
      console.log(this.productImgCurrentOffset);
      if (this.productImgCurrentOffset >= -2) return;
      this.productImgCurrentOffset += this.productImgFatherWith / num;
    },
    onClick_1() {
      alert(1);
    },
    // 立即购买
    buyGood() {
      // 判断用户是否登录
      if (this.$store.state.userInfo.userInfo == "" || this.$store.state.userInfo.userInfo == undefined) {
        this.$router.push({ path: "/login" });
        return;
      }

      if(!this.showOrder) {
        this.$message.warning("您的订单已提交，客服将在第一时间联系您，请耐心等待。")
        return
      }
      // 填写订单信息
      this.dialogFormVisible = true;

      // this.$router.push({path:'/pay',params:{orderId:14}})
      // alert(1);
    },
    // 确认订单信息
    sumbitOrderInfo() {
      productApi
        .syncAddOrderInfo({
          infoCompany: this.orderInfo.companyName,
          orderInfoContacts: this.orderInfo.name,
          orderInfoPhone: this.orderInfo.phone,
          userType: this.orderInfo.userType,
        })
        .then((result) => {
          if (result.code == 200) {
            //如果勾选了不再提醒，写入cookie
            this.$confirm('您的订单已提交，客服将在第一时间联系您，请耐心等待。', '下单成功').then(() => {
              //关闭窗口
              this.dialogFormVisible = false;
              // 生成订单
              this.pay();
            })
          }
          if (result.code == 400) {
            this.$message.error("保存订单联系信息失败");
          }
          //关闭窗口
          this.dialogFormVisible = false;
        });
    },
    // 生成订单 通过邮件方式下单
    pay() {
      let obj = this;
      var arr = window.location.href.split("?");
      let softwareGoodsUrl = arr[0] + "?productId=" + obj.productParams[0].softwareGoodsId;
      let good = {
        softwareGoodsId: obj.productParams[0].softwareGoodsId,
        softwareGoodsName: obj.productParams[0].softwareGoodsName,
        // 规格id
        specsId: obj.specsId,
        // 规格名称
        specsName: obj.specsName,
        // 规格类型id
        specsTypeId: obj.specsTypeId,
        // 规格类型名称
        specsTypeName: obj.specsTypeName,
        softwareGoodsUrl: softwareGoodsUrl,
        softwareNum: obj.amount,
        goodsPrice: obj.price,
        softwareAllPrice: obj.totalPrice + '',
        softwareGoodsType: obj.productParams[0].softwareGoodsType,
        yearOfPurchase: obj.time,
        paymentType: 2, //todo 不加导致报错 付费类型(1: 按年付费 2:永久付费)
      };
      let goodsList = new Array();
      goodsList.push(good);

      productApi
        .syncPlaceAnOrder({
          softwareOrderType: 0, // 0非购物车
          orderGoodsVOList: goodsList,
          existOrderId: md5Js.hex_md5(obj.productParams[0].softwareGoodsId),
          couponId: "",
          fromSource: "null",
        })
        .then((result) => {
          if (result.code == 200) {
            this.showOrder = false;
            this.buyerMsg('下单成功');
          } else if (result.code == 209) {
            this.$message.error(result.message);
          } else {
            this.$message.error("下单失败！");
          }
        });
    },
    // 加入购物车
    addCar() {
      let obj = this;
      if (this.$store.state.userInfo.userInfo == "" || this.$store.state.userInfo.userInfo == undefined) {
        this.$router.push({ path: "/login" });
        return;
      }
      let goodsActivityId = "";
      if (obj.ecGoodsActivity != null && obj.ecGoodsActivity != "") {
        goodsActivityId = obj.ecGoodsActivity.goodsActivityId;
      }
      productApi
        .syncAddCar({
          softwareGoodsId: obj.productParams[0].softwareGoodsId,
          //规格id
          specsId: obj.specsId,
          //规格类型id
          specsTypeId: obj.specsTypeId,
          //活动id
          goodsActivityId: goodsActivityId,
          //付费类型
          paymentType: obj.productParams[0].softwareGoodsType,
          //购买年限
          yearOfPurchase: obj.time,
          //数量
          number: obj.amount,
          //单价
          softwarePrice: obj.price,
          //总价
          softwareAllPrice: obj.totalPrice,
        })
        .then((result) => {
          if (result.code == 200) {
            obj.$message.success("商品已成功加入购物车！");
          } else {
            obj.$message.error("加入购物车失败，请重试！");
          }
        });
    },
    // 切换商品图片
    changeImg(item) {
      this.goodImg = item.goodsImageUrl;
    },
    // 选中规格
    selectProduct(item, index) {
      // 单价
      this.price = item.goodsPrice;
      this.productSpe.forEach((item) => {
        item.isSelect = false;
      });
      item.isSelect = true;
      // 保存选中规格的相关信息
      this.specsId = item.specsId;
      this.specsName = item.specsName;
      this.specsTypeId = item.specsTypeId;
      this.amount = 1
      this.countTotalPrice()

    },
    introduceSelectMethod(item) {
      this.introduceSelect.forEach((item) => {
        item.isSelect = false;
      });
      console.log(123);
      item.isSelect = true;
    },
  },
  created() {
    this.timer = setInterval(this.scroll, this.duration);
    // 获取url携带的参数productId（产品ID）
    let url = this.$route.fullPath;
    console.log(url)
    if (url.indexOf("productId") != -1) {
      console.log("产品Id：" + this.$route.query.productId);
      this.softwareGoodsId = this.$route.query.productId;
      this.getSoftwareGoodsInfoById();
      this.isFollow();
    }
  },
  watch:{
    'time'(newI,oldI) {
      if (newI !== oldI) {
        let obj = this;
        //合计
        obj.totalPrice = obj.price * obj.amount * obj.time;
        obj.totalPrice = obj.totalPrice.toFixed(2);
      }
    },
    'amount'(newI,oldI) {
      if (newI !== oldI) {
        let obj = this;
        //合计
        obj.totalPrice = obj.price * obj.amount * obj.time;
        obj.totalPrice = obj.totalPrice.toFixed(2);
      }
    },

  }
};
</script>
<style>
.el-button--primary  {
  background: #f65d59 !important ;
  border-color: #f65d59 !important;
}
</style>
<style scoped>

.time_input{
  margin-left: 15px;
}
.el-button--primary  {
  background: #f65d59 !important ;
  border-color: #f65d59 !important;
}
.input-count {
  border: unset;
  width: 10px;
}
/deep/.el-input__inner{
  border: 1px solid #f56e65 !important;
  border-radius: 6px;
}
/deep/ .el-input-number__decrease  {
  color: unset;
  border-right: 1px solid #f56e65;
  background: #ffffff;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  width: 41px;
}
/deep/ .el-input-number__increase{
  color: unset;
  border-left: 1px solid #f56e65;
  background: #ffffff;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  width: 41px;
}
</style>
<style lang="scss" scoped>
@import "../../assets/css/common.scss";
@import "../../views/product/productDescription.scss";
.cursor-item {
    cursor: pointer;
}
.row-roll-product {
  background: #f7f7f7;
}
.slices-img {
  height: 100%;
  width: 10vw;
}
.margin-right {
  margin-right: 1vw;
}
.slices-center {
  justify-content: center;
  display: flex;
}
.bg-purple {
  overflow: hidden;
  position: relative;
  //width: 200px;
  //height: 300px;
}
.swiper-box {
  display: flex;
  width: auto;
  transition: all 0.2s;
}

[class^="el-icon-arrow-"] {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 30px;
  padding: 10px 0;
  cursor: pointer;
}
.select_color {
  background-color: #3e3e3e;
}
.no_select_color {
  background-color: #f6f6f6;
}
.select_font_color {
  color: white;
}
.no_select_font_color {
  color: #414141;
}
.product_scroll::-webkit-scrollbar {
  display: none;
}
.product_scroll {
  height: 17vw;
  overflow-y: auto;
}

.introduce_select {
  color: black;
}

.introduce_no_select {
  color: #414141;
}

.text_31_html p {
  overflow-y: auto;
  height: 100%;
}
@media screen and (max-width: 767px) {
}

/* 4. 大桌面显示器（大于等于768px）布局容器宽度为768px */
@media screen and (min-width: 768px) {
}
</style>
